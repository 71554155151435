import React, { useState } from "react";
import { Header_v2 } from "../../components/Blocks/Header";
// import { StyledBadge } from '../../components/UI/Badge';
import { CTA_v1 } from '../../components/Blocks/CTA';
import { Icon } from '@iconify-icon/react';
import { Helmet } from 'react-helmet-async';
import header_solution_manufacture from '../../assets/header/header_solution_manufacture.webp';
// import header_solution_manufacture from '../../assets/header/header_solution_manufacture_alt.jpg';
import { FormattedMessage } from 'react-intl';

import simulasi_produk from '../../assets/product/manufacture/simulasi_produk.jpg';
import barcode_produksi from '../../assets/product/manufacture/barcode_produksi.jpg';
import record_produksi from '../../assets/product/manufacture/record_produksi.png';


function Manufacture() {
  const [tab, setTab] = useState(1);

  return (
    <>
      <Helmet>
          <title>Manufacture Solution | DSN</title>
          <meta name="description" content="Selamat datang di website resmi PT.Delta Solusi Nusantara." />
          <meta name="keywords" content="erp solution, odoo consultant, erp business" />
      </Helmet>
      <Header_v2 tag_1="manufacture_solution_tag_1" tag_2="manufacture_solution_tag_2">
        <img alt="Manufacture Product" src={header_solution_manufacture} />
      </Header_v2>
      <section className="px-5 lg:px-20 py-12 lg:py-24">
          <h1 className="display-sm lg:display-lg font-semibold text-left lg:text-center">Sistem yang tepat untuk Manufaktur</h1>
          <p className="text-medium lg:text-extralarge font-medium w-full lg:w-3/5 text-left lg:text-center mx-auto mb-10 lg:mb-16">Semua yang Anda butuhkan ada di satu platform, simpel dan cepat, kelola proses Manufaktur kamu dengan lebih mudah.</p>

          {/* mobile version */}
          <div className="flex flex-col gap-y-16 block lg:hidden">
            <div>
              <img src={simulasi_produk} width={'100%'} className="mb-10 lg:mb-16"/>
              <div className="flex flex-col justify-between display-xs font-semibold ">
                  <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                    <Icon icon="ic:round-document-scanner" />
                  </div>
                  <p><FormattedMessage id="manufacture_feature_1" /></p>
              </div>
            </div>
            <div>
              <img src={barcode_produksi} width={'100%'} className="mb-10 lg:mb-16"/>
              <div className="flex flex-col justify-between display-xs font-semibold ">
                  <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                    <Icon icon="ic:round-aod" />
                  </div>
                  <p><FormattedMessage id="manufacture_feature_2" /></p>
              </div>
            </div>
            <div>
              <img src={record_produksi} width={'100%'} className="mb-10 lg:mb-16"/>
              <div className="flex flex-col justify-between display-xs font-semibold ">
                  <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                    <Icon icon="ic:round-auto-awesome" />
                  </div>
                  <p><FormattedMessage id="manufacture_feature_3" /></p>
              </div>
            </div>
          </div> 

          {/* desktop version */}
          <div className="hidden lg:block">
            <img src={tab == 1 ? simulasi_produk : tab == 2 ? barcode_produksi : record_produksi} width={'100%'} className="mb-16"/>
            <div className="flex justify-between display-xs font-semibold">
              <div className={"pt-6 pr-6 pb-6 cursor-pointer basis-1/3 " + (tab == 1 ? 'border-t-2 border-primary' : '')} onClick={() => setTab(1)}>
                <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                  <Icon icon="ic:round-precision-manufacturing" />
                </div>
                <p><FormattedMessage id="manufacture_feature_1" /></p>
              </div>
              <div className={"pt-6 pr-6 pb-6 cursor-pointer basis-1/3 " + (tab == 2 ? 'border-t-2 border-primary' : '')} onClick={() => setTab(2)}>
                <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                  <Icon icon="ic:round-qr-code-scanner" />
                </div>
                <p><FormattedMessage id="manufacture_feature_2" /></p>
              </div>
              <div className={"pt-6 pr-6 pb-6 cursor-pointer basis-1/3 " + (tab == 3 ? 'border-t-2 border-primary' : '')} onClick={() => setTab(3)}>
                <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                  <Icon icon="ic:round-text-snippet" />
                </div>
                <p><FormattedMessage id="manufacture_feature_3" /></p>
              </div>
            </div>
          </div>
      </section>
      <CTA_v1/>
    </>
  );
}

export default Manufacture;
