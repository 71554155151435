import React, { useState } from "react";
import { Header_v2 } from "../../components/Blocks/Header";
import { CTA_v1 } from '../../components/Blocks/CTA';
import { Icon } from '@iconify-icon/react';
import header_solution_accounting from '../../assets/header/header_solution_accounting.webp';
import { FormattedMessage } from 'react-intl';

import ai_accounting from '../../assets/product/accounting/ai_accounting.jpg';
import foto_struk from '../../assets/product/accounting/foto_struk.jpg';
import transaksi_otomatis from '../../assets/product/accounting/transaksi_otomatis.jpg';

function Accounting() {
  const [tab, setTab] = useState(1);
  return (
    <>
      <Header_v2 tag_1="accounting_solution_tag_1" tag_2="accounting_solution_tag_2">
        <img alt="Manufacture Product" src={header_solution_accounting} />
      </Header_v2>
      <section className="px-5 lg:px-20 py-24">
          <h1 className="display-sm lg:display-lg font-semibold text-left lg:text-center">Level Up Kualitas Akuntansi Bisnis Kamu</h1>
          <p className="text-medium lg:text-extralarge font-medium w-full lg:w-3/5 text-left lg:text-center mx-auto mb-10 lg:mb-16">Nikmati proses yang lebih efisien, laporan yang lebih akurat, dan kontrol keuangan yang lebih baik untuk membawa bisnis Anda ke level berikutnya.</p>
          {/* mobile version */}
          <div className="flex flex-col gap-y-16 block lg:hidden">
            <div>
              <img src={ai_accounting} width={'100%'} className="mb-10 lg:mb-16"/>
              <div className="flex flex-col justify-between display-xs font-semibold ">
                  <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                    <Icon icon="ic:round-document-scanner" />
                  </div>
                  <p><FormattedMessage id="accounting_feature_1" /></p>
              </div>
            </div>
            <div>
              <img src={foto_struk} width={'100%'} className="mb-10 lg:mb-16"/>
              <div className="flex flex-col justify-between display-xs font-semibold ">
                  <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                    <Icon icon="ic:round-aod" />
                  </div>
                  <p><FormattedMessage id="accounting_feature_2" /></p>
              </div>
            </div>
            <div>
              <img src={transaksi_otomatis} width={'100%'} className="mb-10 lg:mb-16"/>
              <div className="flex flex-col justify-between display-xs font-semibold ">
                  <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                    <Icon icon="ic:round-auto-awesome" />
                  </div>
                  <p><FormattedMessage id="accounting_feature_3" /></p>
              </div>
            </div>
          </div> 
          {/* desktop version */}
          <div className="hidden lg:block">
            <img src={tab == 1 ? ai_accounting : tab == 2 ? foto_struk : transaksi_otomatis} width={'100%'} className="mb-16"/>
            <div className="flex justify-between display-xs font-semibold ">
              <div className={"pt-6 pr-6 pb-6 cursor-pointer basis-1/3 " + (tab == 1 ? 'border-t-2 border-primary' : '')} onClick={() => setTab(1)}>
                <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                  <Icon icon="ic:round-precision-manufacturing" />
                </div>
                <p><FormattedMessage id="accounting_feature_1" /></p>
              </div>
              <div className={"pt-6 pr-6 pb-6 cursor-pointer basis-1/3 " + (tab == 2 ? 'border-t-2 border-primary' : '')} onClick={() => setTab(2)}>
                <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                  <Icon icon="ic:round-qr-code-scanner" />
                </div>
                <p><FormattedMessage id="accounting_feature_2" /></p>
              </div>
              <div className={"pt-6 pr-6 pb-6 cursor-pointer basis-1/3 " + (tab == 3 ? 'border-t-2 border-primary' : '')} onClick={() => setTab(3)}>
                <div className="p-2 border w-10 h-10 rounded-xl shadow mb-4 flex flex-row justify-center items-center bg-primary text-white">
                  <Icon icon="ic:round-text-snippet" />
                </div>
                <p><FormattedMessage id="accounting_feature_3" /></p>
              </div>
            </div>
          </div>
      </section>
      <CTA_v1/>        
    </>
  );
}

export default Accounting;
